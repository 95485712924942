import {
  Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon,
  InputGroupText, InputGroup, Col, Spinner, Container, Row
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastLayout from './partials/ToastifyMsg';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import UserContext from "../components/UserContext";
import { login } from "../services/LoginUser";
import { setUserToken , getUserToken} from '../services/roleService'
import { apiUrl } from '../services/globalService';
// import { userLoginAPI } from '../services/LoginUser';

const Login = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const userContext = useContext(UserContext);
  const history = useHistory()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [loading, setLoading] = useState(false);

  var emailInput = React.createRef(); //document.getById
   var passwordInput = React.createRef(); 
   let apiLogin = apiUrl + "auth/login";
  const Loading = () => {
    return (<Spinner style={{ color: '#6c6c6c' }} animation="border" size="md" />);
  }
  const onLoginChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value)
    }
    if (e.target.name === "password") {
      setPassword(e.target.value)
    }
  }

  const doLogin = () => {
    let data = {
       email:email,
       password:password,
       web:true
    };
    console.log('data45',data)
    fetch(apiLogin, {
       method: 'post',
       headers: {
          'Content-Type': 'application/json;charset=utf-8'
       },
       body: JSON.stringify(data)
    })
       .then(response => response.json())
       .then(result => {
          // setting the login TRUE
          if (result.result.status == true) {
             setUserToken(result.result.token)
             getUserToken(result.result.user)
             userContext.doLogin('admin');
             console.log('true')
            setTimeout(() => {
                 history.push("/user-list")
            }, 100);
          } 
          else {
            toast.error(ToastLayout(result.result.message));
          }
       })
       .catch(function (error) {
          console.log(error);
       });
 }
  return (
    <>
      <div className="main-content" ref={mainContent} style={{ height: '100vh' }}>
        <div className="header py-7 py-lg-5">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Welcome!</h1>
                  {/* <p className="text-lead " style={{ color: "#fff" }}>
                  </p> */}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--8">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="btn-wrapper text-center"
                    style={{
                      marginLeft: '0px',
                      paddingRight: '0px',
                      paddingBottom: '0px',
                      paddingLeft: '0px',
                      borderBottomLeftRadius: '15px',
                      borderBottomRightRadius: '15px',
                      backgroundColor: 'transparent'
                    }}>
                    <img
                      style={{ width: '60%',marginTop:10 }}
                      alt="..."
                      src="Bizbro-Logo/NameLogo/1x/Transparent.png"
                    />
                    {/* <img src="https://bizbro.ca/wp-content/uploads/2022/06/logo-bizbro-trans.png" 
                       style={{ width: '60%' }}
                     alt="Logo">
                    </img> */}

                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          style={{ color: "#000" }}
                          name="email"
                          id="email"
                          placeholder="Email"
                          type="email"
                          ref={emailInput}
                          onChange={(e) => onLoginChange(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          style={{ color: "#000" }}
                          name="password"
                          placeholder="Password"
                          type="password"
                          ref={passwordInput}
                          onChange={(e) => onLoginChange(e)}
                          autoComplete="new-password"
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      {loading ? Loading() :
                        <Button onClick={doLogin} className="my-4" 
                        style={{ 
                          backgroundColor: 'rgb(55 116 118 / 95%)',
                        color: 'rgb(255, 255, 255)',
                        width: '-webkit-fill-available',
                        borderWidth: 2 }} type="button">
                          Sign in
                        </Button>}
                        <ToastContainer limit={1} />
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
