const token = "userToken"
const userKey = "userDetail"

export const setLocalUser = (user)=> {
    localStorage.setItem(userKey, window.btoa(JSON.stringify(user)));
}
export const setUserToken = (userToken) => {
    localStorage.setItem(token,userToken);
}
export const getUserToken = () => {
    let token =  localStorage.getItem("userToken");
    return token
}
