import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Table, Container, Row, Spinner } from "reactstrap";
import Header from "components/Headers/Header.js";
import { deleteUser, getUser } from '../services/userService';
import { useHistory } from "react-router-dom";
import '../assets/css/pages/table-list.css'

export default function UserList() {
  const history = useHistory()
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);

  const refreshlist = () => {
    setLoading(true);
    getdata();
    if (loading) { setLoading(false) }
  }

  useEffect(() => {
    reloadUser()
  }, [])

  let reloadUser = async () => {
    let user = await getUser();
    console.log(">user.....",user);
    setUser(user.result.users);
  }
  let addUser = async () => {
    history.push("/add-user")
  }
  let deleteUserAction = async (id) => {
    await deleteUser(id)
    reloadUser()
  }

  const deviceListRecords = () => {
    if (user?.length > 0 && !loading) {
      return user.map((item, index) => {
        return (
          <tbody key={index} style={{backgroundColor:'rgba(1229,221,221,0.1)'}}>
            <tr>
              <td style={{ float: "left" }}>{item.email}</td>
              <td>{item.deviceModel}</td>
              <td>{item.deviceEUI}</td>
              <td>{item.location}</td>
              <td>
                <i id="editBtn"
                  style={{
                    cursor: 'pointer',
                    visibility: 'visible',
                    color: '#000000',
                    marginTop: 5,
                    rotationPoint: '50% 50%'
                  }} 
                  onClick={() => { history.push(`/edit-user/${item._id}`) }} className="fas fa-edit" />
             
                {/* <i id="refreshbtn"
                  style={{
                    cursor: 'pointer',
                    visibility: 'visible',
                    color: '#000000',
                    marginTop: 5,
                    rotationPoint: '50% 50%'
                  }} 
                  onClick={() => { deleteUserAction(item._id) }}
                   className="fas fa-trash" /> */}

              </td>
            </tr>
          </tbody>
        )
      })
    } else {
      return (
        <>
          {loading ?
            <tbody>
              <tr>
                <th colSpan="7">
                  <Spinner animation="border" size="md" />
                </th>
              </tr>
            </tbody>
            :
            <tbody>
              <tr >
                <th colSpan="7" style={{ textAlign: 'center' }}>No Record Found!</th>
              </tr>
            </tbody>
          }
        </>
      )
    }
  }
  return (
    <>
      <div>
        <Header />
        <Container className="mt--7" fluid style={{ marginTop: '-8rem !important' }}>
          <Row>
            <div className="col">
              <Card className="shadow" style={{ height: '78.4vh' }}>
                <CardHeader className="border-0" 
                            style={{
                              padding:5,
                              marginBottom:10,
                              marginRight:10
                            }}
                >
                  <h3 className="mb-0" style={{fontSize:22}}>User List
                    <i id="addBtn"
                      style={{
                        cursor: 'pointer',
                        visibility: 'visible',
                        color: '#000000',
                        float: 'right',
                        marginTop: 5,
                        transition: 'transform .5s linear',
                        transformStyle: 'preserve-3D',
                        position: 'sticky',
                        rotationPoint: '50% 50%'
                      }} onClick={() => { addUser() }} className="fas fa-plus" />
                  </h3>
                </CardHeader>
                <Table striped hover responsive bordered className="align-items-center table-flush">
                  <thead className="thead-light" >
                    <tr >
                      <th scope="col" style={{color:'black',fontSize:13,fontWeight:'bold'}}>Email</th>
                      <th scope="col" style={{color:'black',fontSize:13,fontWeight:'bold'}}>Device Model</th>
                      <th scope="col" style={{color:'black',fontSize:13,fontWeight:'bold'}}>Device EUI</th>
                      <th scope="col" style={{color:'black',fontSize:13,fontWeight:'bold'}}>Location</th>
                      <th scope="col" style={{color:'black',fontSize:13,fontWeight:'bold'}}>Action</th>
                    </tr>
                  </thead>
                  {deviceListRecords()}
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}