import { Row, Col, Nav } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer" style={{background: "white"}}>
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left " style={{color: "#fff"}}>
            © 
            {/* {new Date().getFullYear()}{" "} */}
            <a
            style={{color:"#fff"}}
              className="font-weight-bold ml-1"
              href="https://bizbro.ca/"
              rel="noopener noreferrer"
              target="_blank"
            >
              bizbro
            </a>
          </div>
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
