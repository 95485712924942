import UserList from "../views/UserList";
import AddUser from "../views/addUser";

var routes = [
  {
    path: "/user-list",
    name: "Users",
    icon: "fas fa-users",
    component: UserList,
    isVisible: true,
    layout: "",
  },
  {
    path: "/add-user",
    name: "addUser",
    icon: "ni ni-bell-55 ",
    component: AddUser,
    isVisible: false,
    layout: "",
  },
  {
    path: "/edit-user/:_id"  ,
    name: "editUser",
    icon: "ni ni-bell-55 ",
    component: AddUser,
    isVisible: false,
    layout: "",
  },
  {path: "/", pathTo:'/user-list', redirect:true} 
];
export default routes;
