import { apiUrl } from "./globalService";
let apiDevice = apiUrl + "devices/";
import { getUserToken } from "../services/roleService";

export let getDeviceList = async () => {
  let tokenData = await getUserToken('token')
  return fetch(apiDevice, {
    method: 'get',
    headers: {
      'Authorization': tokenData,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => { return response; }).catch(function (error) {
    });
}

export let getDeviceListInfo = async () => {
  let tokenData = await getUserToken('token')
  return fetch(apiDevice, {
    method: 'get',
    headers: {
      'Authorization': tokenData,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => { return response; }).catch(function (error) {
    });
}

export let getDeviceById = async(_id) => {
  let tokenData = await getUserToken('token')
  return fetch(apiDevice + "/" + _id, {
    method: 'get',
    headers: {
      'Authorization': tokenData,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      console.log('get43Idresponse',response)
      return response;
    }).catch(function (error) {
    });
}