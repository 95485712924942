import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer, toast } from 'react-toastify';

export default function ToastLayout(msg) {
    toast.error(msg, {
        position: "top-center",
        theme: "colored",
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: false,
        hideProgressBar:true,
        draggable: true,
    })
}
