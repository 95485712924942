import { apiUrl } from './globalService';
const userLogin = apiUrl + "auth/login"

export var login = (data) => {
    return fetch(userLogin, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 
                'Content-Type': 'application/json' ,
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

