// import apiUrl from '@/service/globalService';
// console.log('apiUrl',apiUrl)
import { apiUrl } from "./globalService";
let apiUser = apiUrl + "users";
import { getUserToken } from "../services/roleService";

export let getUser = async () => {
  let tokenData = await getUserToken('token')
  return fetch(apiUser, {
    method: 'get',
    headers: {
      'Authorization': tokenData,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => { return response; }).catch(function (error) {
    });
}

export const addUser = async (user) => {
  let tokenData = await getUserToken('token')
  return fetch(apiUser, {
    method: 'post',
    headers: {
      'Authorization' : tokenData,
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(user)
  })
    .then(response => response.json())
    .then(response => {
      console.log(">>suceesss addedd", response);
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getUserById = async(_id) => {
  let tokenData = await getUserToken('token')
  return fetch(apiUser + "/" + _id, {
    method: 'get',
    headers: {
      'Authorization': tokenData,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
    });
}
export const deleteUser = async ( id ) => {
  let tokenData = await getUserToken('token')
  return fetch(apiUser + "/" + id, {
    method: 'delete',
    headers: {
      'Authorization' : tokenData,
      'Content-Type': 'application/json'
    },
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log("error",error);
    });
}
export let updateUser = async(user) => {
  let tokenData = await getUserToken('token')
  return fetch(apiUser + "/" + user._id, {
    method: 'put',
    headers: {
      'Authorization' : tokenData,
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(user)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
    });
}

