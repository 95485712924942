import React from 'react';
import indexRoutes from './route/index';
import { Route, Switch } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import Protected from './components/Protected';
import UserCtx from './components/UserContext';
import { useState } from 'react';
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

function App() { //context
  var loginStatus = () => {
    if (localStorage.getItem('loggedInType') != null
      && localStorage.getItem('loggedInType') == "admin") {
      return localStorage.getItem('loggedInType')
    } else {
      return "logged-out";
    }
  }
  const [loggedInType, setLoggedInType] = useState(loginStatus());

  
  return (
    < HashRouter >
      <UserCtx.Provider
        value={{
          loggedInType,
          doLogin: (code) => {
            console.log("doLogin ", code)
            if (code == "admin" || code == "logged-out") {
              setLoggedInType(code);
              localStorage.setItem('loggedInType', code);
            } else {
              localStorage.removeItem('loggedInType');
            }
          }
        }}
      >
        <Switch > {
          indexRoutes.map((prop, key) => {
            return <Route path={prop.path}
              key={key}
              component={prop.component}
            />;
          })
        }
          <Protected userType={loggedInType} path="/"></Protected>
        </Switch>
      </UserCtx.Provider>
    </HashRouter >
  );
}

export default App;