import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import React,{useContext} from 'react';
import { useHistory } from "react-router-dom";
import UserContext from "../../components/UserContext";

const AdminNavbar = () => {
  const userContext = useContext(UserContext);
  const history = useHistory();

  var doLogout = () => {
    userContext.doLogin("logged-out");
    history.push("/admin")
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/">
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle" style={{backgroundColor:'white'}}>
                  <i style={{
                      cursor: 'pointer',
                      visibility: 'visible',
                      color: '#000000',
                      fontSize:30
                    }} className="fas fa-user" />
                    {/* <img
                      alt="..."
                      src={
                        require("../../assets/img/theme/img_avatar.jpg")
                          .default
                      }
                    /> */}
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    {/* <span className="mb-0 text-sm font-weight-bold" style={{color:'white'}}>
                      Admin
                    </span> */}
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem href="" onClick={doLogout}>
                  <i className='fas fa-sign-out-alt' style={{color:'red'}}></i>
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
