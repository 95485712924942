import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Table, Container, Row, Spinner, CardBody, CardFooter } from "reactstrap";
import Header from "components/Headers/Header.js";
import { addUser, deleteUser, getUserById, updateUser } from '../services/userService';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Col, Modal } from 'react-bootstrap';
import './../assets/css/pages/add-edit-form.css'
import { getDeviceList, getDeviceById, getDeviceListInfo } from 'services/Device';
import Select from "react-select";
import { useHistory, useLocation, } from 'react-router-dom';
import ToastLayout from './partials/ToastifyMsg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { emailId } from './validations/input-validations';
export default function AddUser({ match }) {
  const params = match.params;
  const history = useHistory();
  const [state, setUserState] = useState({
    _id: 0,
    buttonLabel: 'Add',
    formLabel: 'Add',
    deleteButton: 'Cancle'
  });

  const [deviceDetail, setdeviceDetail] = useState([]);

  const [deviceInfo, setdeviceInfo] = useState({
    name: ''
  });
  useEffect(() => {
    reloadDevice();
    if (typeof (params._id) !== 'undefined') {
      fetchUser(params._id)
    }
  }, [])

  let fetchUser = async (_id) => {
    let userData = await getUserById(_id);
    console.log(">>fetchUser ", userData.result.user.deviceInfo)
    if (userData.result.user) {
      setUserState({ ...userData.result.user, buttonLabel: 'Update', formLabel: 'Edit', deleteButton: 'Delete' })
      console.log(">userData.result.user..", userData.result.user.deviceInfo);

    }
    setdeviceInfo(userData.result.user.deviceInfo);
  }

  let reloadDevice = async () => {
    let deviceRecord = await getDeviceList();
    setdeviceDetail(deviceRecord.result || [])
  }

  let handleChange = (e) => {
    setUserState({ ...state, [e.target.name]: e.target.value });
  }

  let deleteUserAction = async (id) => {
    if (id) {
      await deleteUser(id)
      history.goBack()
    } else {
      history.goBack()
    }
  }
  let submitBtn = async () => {
    let newItem = {}
    if (typeof (params._id) !== 'undefined') {
      newItem = {
        email: state.email,
        // password: state.password,
        deviceModel: deviceInfo.name,
        deviceEUI: deviceInfo.devEUI,
        location: state.location,
        deviceInfo: deviceInfo
      };
    } else {
      newItem = {
        email: state.email,
        password: state.password,
        deviceModel: deviceInfo.name,
        deviceEUI: deviceInfo.devEUI,
        location: state.location,
        deviceInfo: deviceInfo
      };
    }
    if (state._id !== 0) {
      if (emailId.emailRegex.test(state.email)) {
        newItem._id = state._id;
        console.log(">newItem:update", newItem);
        await updateUser(newItem);
        history.goBack()
      } else {
        toast.error(ToastLayout(emailId.emailInputError))
      }
    }
    else {
      if (emailId.emailRegex.test(state.email)) {
        console.log(">newItem:add", newItem);
        await addUser(newItem)
        history.goBack()
      }
      else {
        toast.error(ToastLayout(emailId.emailInputError))
      }
    }

  }
  const updatePropsReactSelect = {}
  return (
    <>
      <div>
        <Header />
        <Container className="mt--7" fluid style={{ marginTop: '-8rem !important' }}>
          <Row>
            <div className="col">
              <Card className="shadow" style={{ height: '78.4vh' }}>
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    <i style={{
                      cursor: 'pointer',
                      visibility: 'visible',
                      color: '#000000',
                      marginTop: 5,
                      transition: 'transform .5s linear',
                      transformStyle: 'preserve-3D',
                      position: 'sticky',
                      rotationPoint: '50% 50%'
                    }} onClick={() => { history.goBack() }} className="fas fa-arrow-left" />
                    &nbsp;&nbsp;&nbsp;{state.formLabel}{" "}User
                  </h3>
                </CardHeader>
                <CardBody>
                  <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                      <div className="add-responsive">Email address :</div>
                    </Col>
                    <Col sm="9">
                      <input
                        name="email"
                        placeholder="Email address"
                        onChange={handleChange}
                        value={state.email}
                        className="input-style-responsive"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" >
                    {typeof (params._id) !== 'undefined' ? (null) : (
                      <>
                        <Col sm="3">
                          <div className="add-responsive">Password :</div>
                        </Col>
                        <Col sm="9">
                          <input
                            name="password"
                            placeholder="Password"
                            onChange={handleChange}
                            value={state.password}
                            className="input-style-responsive"
                          type="password"
                          />
                        </Col>
                      </>
                    )
                    }
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                      <div className="add-responsive">Device :</div>
                    </Col>
                    <Col sm="9">
                      {deviceInfo?.name !== "" && <Select
                        className="dropDown-style"
                        options={deviceDetail}
                        getOptionLabel={e => e.name}
                        getOptionValue={e=>e}
                        onChange={(value) => setdeviceInfo(value)}
                        defaultValue={{ name: deviceInfo?.name, value: deviceInfo }}
                      /> }
                      {deviceInfo?.name === ""?
                      <Select
                      className="dropDown-style"
                      options={deviceDetail}
                      getOptionLabel={e => e.name}
                      getOptionValue={e=>e}
                      onChange={(value) => setdeviceInfo(value)}
                      // defaultValue={{ name: deviceInfo.name, value: deviceInfo }}
                    /> 
                      :null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                      <div className="add-responsive">Device Model :</div>
                    </Col>
                    <Col sm="9">
                      <input
                        name="deviceModel"
                        placeholder="Device Model"
                        onChange={handleChange}
                        value={deviceInfo?.name}
                        className="input-style-responsive"
                        disabled
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                      <div className="add-responsive">Device EUI :</div>
                    </Col>
                    <Col sm="9">
                      <input
                        name="deviceEUI"
                        placeholder="Device EUI"
                        onChange={handleChange}
                        value={deviceInfo?.devEUI}
                        className="input-style-responsive"
                        disabled
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                      <div className="add-responsive">Location :</div>
                    </Col>
                    <Col sm="9">
                      <input
                        name="location"
                        placeholder="Location"
                        onChange={handleChange}
                        value={state.location}
                        className="input-style-responsive"
                      />
                    </Col>
                  </Form.Group>
                </CardBody>
                <CardFooter>
                  <ToastContainer limit={1} />
                  <button className="fm-button"
                    onClick={() => { deleteUserAction(state._id) }}
                  > {state.deleteButton}</button>
                  <button className="form-button"
                    onClick={submitBtn}>{state.buttonLabel}</button>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}